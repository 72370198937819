import React, { useState } from "react";
import { createPortal } from "react-dom";
import "./NotificationWidget.css";
import BellIcon from "../assets/svg/bell.svg";
import NotificationBell from "../assets/svg/notification.svg";
import { ReactComponent as NotificationDots } from "../assets/svg/NotificationDots.svg";
import { ReactComponent as NotificationWatch } from "../assets/svg/NotificationWatch.svg";
import { ReactComponent as NavigateBack } from "../../src/assets/svg/BackArrow.svg";
import { useEffect } from "react";
import { useQuery } from "react-query";
import notificationProfile from "../../src/assets/png/notificationProfile.png";
import { toast } from "react-toastify";
import NotificationsView from "./NotificationView";
import axios from "axios";
import { io } from "socket.io-client";

function CoachNotificationWidget({ coachId }) {
  const [isClicked, setIsClicked] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [notificationNumber, setNotificationNumber] = useState(0);

  const getuserNotifications = async () => {
    const userDetails = localStorage.getItem("userData");
    const parsedData = JSON.parse(userDetails);
    try {
      const userNotificationsResponse = await axios.get(
        `${process.env.REACT_APP_API_URL}/v1/notifications/${parsedData?.id}`
      );
      if (userNotificationsResponse.status === 200) {
        const _notifications = userNotificationsResponse.data;
        _notifications.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
        setNotifications(_notifications);
        setNotificationNumber(
          _notifications?.filter((n) => !n.isRead).length || 0
        );
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    const userDetails = localStorage.getItem("userData");
    const parseduserDetails = JSON.parse(userDetails);
    console.log(
      "socket_url",
      `${process.env.REACT_APP_WEBSOCKET_URL}?userId=${parseduserDetails?.id}`
    );
    console.log("UserId and CoachPropUserId", parseduserDetails?.id, coachId);
    const socket = io(
      `${process.env.REACT_APP_WEBSOCKET_URL}?userId=${parseduserDetails?.id}`
    );
    socket.on("receive_notification", (data) => {
      console.log("socket is on");
      setNotifications((prevNotifications) => [data, ...prevNotifications]);
      setNotificationNumber(
        (prevNotificationNumber) => prevNotificationNumber + 1
      );
    });
    getuserNotifications();
    return () => {
      socket.off("receive_notification");
    };
  }, []);

  const userId = sessionStorage.getItem("userId");
  const idToFilter = Number(userId);

  const handleNotifications = () => {
    setIsClicked((prev) => !prev);
  };







  return (
<div className="notif-container">
      <div className="bellIconWrapper" onClick={handleNotifications}>
        <img src={BellIcon} alt="" id="notifImg" />
        <div className="notificationNumberDiv">
          <span>{notificationNumber}</span>
        </div>
      </div>

      {isClicked && (
        <NotificationsView
          notifications={notifications}
          setNotifications={setNotifications}
          setNotificationNumber={setNotificationNumber}
        />
      )}
    </div>
  );
}

export default CoachNotificationWidget;
