import Navbar from "../components/navbar/Navbar";
import Sidebar from "../components/sidebar/Sidebar";

const SurveyForm = () => {
  return (
    <div className="survey-form-container">
      <Navbar />
      <div className="content-wrapper">
        <Sidebar />
        <div className="survey-list-container">
          <h2>Your Survey Forms</h2>
          {/* {surveyForms.length > 0 ? (
            surveyForms.map((form) => (
              <SurveyCard
                key={form.id}
                formName={form.formName}
                createdAt={form.createdAt}
                inputs={form.formLayoutComponents}
              />
            ))
          ) : (
            <p>No survey forms available.</p>
          )} */}
        </div>
      </div>
    </div>
  );
};

export default SurveyForm;
