import { useEffect, useRef, useState } from "react";
import MultiSelectDropdown from "../../../components/MultiSelect";
import "./reschedulebooking.css";
import moment, { duration } from "moment";
import { toast } from "react-toastify";
import SingleSelectDropdown from "../../../components/SingleSelect";
import { getAdminDetails, hours, token } from "../../../utils/utilityFunctions";
import axios from "axios";

const RescheduleBooking = ({ selectedBooking, open, close, refetchEvents }) => {
  const [selectedFacilties, setSelectedFacilties] = useState([]);
  const [facilityData, setFacilityData] = useState([]);
  const [isCancelledClicked, setIsCancelledClicked] = useState(false);
  const [adminId, setAdminId] = useState(null);
  const cancelNoteRef = useRef(null);
  console.log("selectedBooking", selectedBooking.start);
  const dateStr = selectedBooking.start;
  const formattedDate = moment(dateStr).format("YYYY-MM-DD");
  console.log("formattedDate", formattedDate);
  const [bookingDetails, setBookingDetails] = useState({
    title: "",
    start: "",
    end: "",
    startTime: "",
    endTime: "",
    facilities: "",
    duration: "",
    notes: "",
    seats: "",
  });

  console.log("Booking Det", bookingDetails);
  const updateFacilities = (e) => {
    const {
      target: { value },
    } = e;
    setSelectedFacilties(typeof value === "string" ? value.split(",") : value);
  };

  const fetchFacilities = async () => {
    const apiUrl = `${window.env_url}/v1/filterfacilities`;
    const token = localStorage.getItem("accessToken");
    try {
      const response = await fetch(apiUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      setFacilityData(data.body[0] || []);
    } catch (error) {
      console.error(error.message);
    }
  };

  const facilities = facilityData.map((data, index) => {
    return {
      name: data.title,
      code: data.id,
    };
  });

  const getDateString = (dateString) => {
    const dateObj = new Date(dateString);
    const year = dateObj.getFullYear();
    const month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
    const day = dateObj.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const getDuration = (startTime, endTime) => {
    const startDate = new Date(startTime);
    const endDate = new Date(endTime);
    const diffInMilliseconds = endDate - startDate;
    const diffInHours = diffInMilliseconds / (1000 * 60 * 60);
    return diffInHours;
  };

  const updateBookingDetails = () => {
    setSelectedFacilties(selectedBooking?.facilities);
    const _bookingDetails = {
      notes: selectedBooking?.notes,
      start: getDateString(selectedBooking?.start),
      duration: getDuration(selectedBooking?.start, selectedBooking?.end),
      startTime: getTime(selectedBooking?.start),
      seats: selectedBooking?.seats,
    };
    setBookingDetails(_bookingDetails);
  };

  const getTime = (dateString) => {
    const dateObj = new Date(dateString);
    const hours = dateObj.getHours();
    const minutes = dateObj.getMinutes().toString().padStart(2, "0");
    const time = `${hours}:${minutes}`;
    return time;
  };

  const getFileUrl = `${window.env_url}/v1/files/`;
  const profileImage = sessionStorage.getItem("profPicture");
  let imgPath;
  if (profileImage?.includes("avataaars.io")) {
    imgPath = profileImage;
  } else {
    imgPath = getFileUrl + profileImage;
  }

  const updateBooking = async (updatedBooking, type) => {
    const token = localStorage.getItem("accessToken");
    try {
      const response = await fetch(
        `${window.env_url}/v1/meetingbookings/${selectedBooking?.id}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(updatedBooking),
        }
      );
      const data = await response.json();
      const userData = JSON.parse(localStorage.getItem("userData"));
      const userId = userData?.id;
      const name = userData.firstname;
      const adminDetails = await getAdminDetails(token);
      console.log("Admin details", adminDetails[0]);
      const adminUserId = adminDetails?.userId;
      console.log("Admin user id", adminUserId);
      let dataToSend = {
        userId: adminId,
        message: name + " has booked a meeting room",
        duration: data.body[0].duration,
        profileImage: imgPath,
      };
      refetchEvents();
      const sendBookingReq = await axios.post(
        `${process.env.REACT_APP_API_URL}/v1/notifications/create`,
        dataToSend
      );
      console.log("sendBooking", sendBookingReq);
    } catch (error) {
      toast.error("Error updating the booking");
    }
  };

  const formRequestBody = () => {
    const startTime = moment(bookingDetails?.startTime, "HH:mm:ss");
    const startTimeMoment = moment(startTime, "HH:mm");
    const endTimeMoment = startTimeMoment
      .clone()
      .add(bookingDetails?.duration, "hours");

    let startDate = moment(bookingDetails?.start);
    let endDate = moment(bookingDetails?.end);

    if (
      !startTimeMoment?._i ||
      !endTimeMoment._i ||
      !selectedFacilties?.length ||
      !bookingDetails?.duration ||
      bookingDetails?.notes === ""
    ) {
      toast.error("Please fill all the fields");
      return;
    }

    if (Number(bookingDetails?.seats) > Number(selectedBooking?.roomCapacity)) {
      toast.error("You've exceeded the seats limit");
      return;
    }

    const roomEvent = {
      prevBookingDate: formattedDate,
      checkInDate: moment(startDate).format("YYYY-MM-DD"),
      checkOutDate: moment(startDate).format("YYYY-MM-DD"),
      checkInTime: startTimeMoment.format("HH:mm:ss"),
      checkOutTime: endTimeMoment.format("HH:mm:ss"),
      facilities: selectedFacilties.map((item, i) => item),
      seats: bookingDetails?.seats,
      duration: bookingDetails?.duration,
      notes: bookingDetails?.notes,
      visibility: "pending",
      isRescheduled: selectedBooking?.isRescheduled
        ? true
        : selectedBooking?.visibility === "accepted"
        ? true
        : false,
    };
    return roomEvent;
  };

  const handleUpdateEvent = async () => {
    const event = formRequestBody();
    event.visibility = "pending";
    console.log("event", event);
    updateBooking(event, "Updated");
    close();
    setTimeout(() => {
      refetchEvents();
    }, 3);
  };

  useEffect(() => {
    fetchFacilities();
    updateBookingDetails();
  }, []);

  useEffect(() => {
    const fetchAdminDetails = async () => {
      try {
        const adminDetails = await getAdminDetails(token);
        console.log("Admin details", adminDetails);
        const adminUserId = adminDetails?.userId;
        setAdminId(adminUserId);
      } catch (error) {
        console.error(error?.message);
      }
    };
    if (token) {
      fetchAdminDetails();
    }
  }, [token]);

  const bookingCancelHandler = () => {
    const event = formRequestBody();
    event.visibility = "rejected";
    event.notes = cancelNoteRef.current.value;
    updateBooking(event, "Cancelled");
    setIsCancelledClicked(false);
    refetchEvents();
    close();
  };

  const CancelContent = () => {
    return (
      <div className="cancel-booking-container">
        <div>
          <div className="cancel-booking-header">Cancel Booking</div>
        </div>
        <div>
          <textarea
            style={{ resize: "none" }}
            placeholder="Comment..."
            ref={cancelNoteRef}
          ></textarea>
        </div>
        <div className="form-sbt-btn reschedule-cancel-dialog-cancel-button">
          <button onClick={bookingCancelHandler}>Submit</button>
        </div>
      </div>
    );
  };

  const cancelHandler = () => {
    setIsCancelledClicked(true);
    // close();
  };
  if (isCancelledClicked) return <CancelContent />;
  return (
    <div className="bmrf reschedule-booking-form-container">
      <header>
        <div>Update Your Booking</div>
      </header>
      <div className="bmrf-form reschedule-form">
        <div className="formFieldContainer">
          <div className="row1">
            <div>
              <label htmlFor="" className="makebold">
                Date
              </label>
              <input
                type="date"
                name="startDate"
                value={bookingDetails?.start}
                onChange={(event) =>
                  setBookingDetails({
                    ...bookingDetails,
                    start: event.target.value,
                  })
                }
              />
            </div>
          </div>

          <div className="row1">
            <div>
              <label htmlFor="" className="makebold">
                Seats
              </label>
              <input
                type="number"
                placeholder={`Seating capacity is ${selectedBooking?.roomCapacity}`}
                name="seats"
                value={bookingDetails?.seats}
                onChange={(event) => {
                  setBookingDetails({
                    ...bookingDetails,
                    seats: event.target.value,
                  });
                }}
              />
            </div>
          </div>

          <div className="row2">
            <div>
              <label htmlFor="" className="makebold">
                Time
              </label>
              <input
                type="time"
                name="startTime"
                value={bookingDetails?.startTime}
                onChange={(event) => {
                  setBookingDetails({
                    ...bookingDetails,
                    startTime: event.target.value,
                  });
                }}
              />
            </div>
          </div>

          <div className="row3" style={{ border: "none" }}>
            <div>
              <label htmlFor="" className="makebold">
                Duration
              </label>
              {/* <input
                type="number"
                name="duration"
                placeholder="Duration"
                value={bookingDetails?.duration}
                onChange={(event) =>
                  setBookingDetails({
                    ...bookingDetails,
                    duration: event.target.value,
                  })
                }
              /> */}
              <SingleSelectDropdown
                options={hours}
                value={bookingDetails?.duration}
                onChange={(event) =>
                  setBookingDetails({
                    ...bookingDetails,
                    duration: event.target.value,
                  })
                }
                label="Duration"
              />
            </div>
          </div>

          <div className="row4">
            <div>
              <MultiSelectDropdown
                value={selectedFacilties}
                onChangeEvent={updateFacilities}
                options={facilities}
                placeholder="Select Facilities"
              />
            </div>
          </div>

          <div className="row5">
            <div className="row5" style={{ display: "block" }}>
              <label htmlFor="" className="makebold">
                Description
              </label>
              <textarea
                type="text"
                name="notes"
                value={bookingDetails?.notes}
                placeholder="Description"
                onChange={(event) =>
                  setBookingDetails({
                    ...bookingDetails,
                    notes: event.target.value,
                  })
                }
              />
            </div>
          </div>
        </div>

        <div className="form-sbt-btn reschedule-form-buttons">
          <button
            onClick={handleUpdateEvent}
            className="reschedule-submit-button"
          >
            {selectedBooking?.visibility === "accepted"
              ? "Reschedule"
              : "Update"}
          </button>
          <button className="reschedule-cancel-button" onClick={cancelHandler}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default RescheduleBooking;
