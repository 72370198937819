import logo from "./logo.svg";
import "./App.css";
import LoginPage from "./components/loginpage/LoginPage";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import QbookDialog from "./dialogs/QbookingDialog";
import QbookingDialog2 from "./dialogs/QbookingDialog2";
import QbookingDialog3 from "./dialogs/QbookingDialog3";
import Navbar from "./components/navbar/Navbar";
import Sidebar from "./components/sidebar/Sidebar";
import Home from "./pages/home/Home";
import NotificationWidget from "./widgets/NotificationWidget";
import MeetingRooms from "./pages/meetingrooms/MeetingRooms";
import Coaches from "./pages/coaches/Coaches";
import RoomDetails from "./pages/home/roomdetails/RoomDetails";
import CoachDetails from "./pages/coaches/coachdetails/CoachDetails";
import CoachReviewDialog from "./dialogs/CoachReviewDialog";
import MeetingRoomDetails from "./pages/meetingrooms/meetingRoomDetails/MeetingRoomDetails";
import BookMeetingRoom from "./pages/meetingrooms/BookMeetingRoom";
import BookCoach from "./pages/coaches/BookCoach";
import BookingSchedule from "./pages/home/homeinnerui/BookingSchedule";
import ReviewCard from "./dialogs/profile-ui/review-card/ReviewCard";
import FilterWidget from "./dialogs/profile-ui/filter-widget/FilterWidget";
import MeetingFilterContextProvider from "./context/context";
import StarRating from "./widgets/StarRating";
import MSideBar from "./components/sidebar/mobile/MSideBar";
import RespReviewDialog from "./dialogs/profile-ui/RespReviewDialog";
import Authentication from "./components/auth/Authentication";
import { QueryClient, QueryClientProvider } from "react-query";
import CalendarWidget from "./widgets/CalendarWidget/CalendarWidget";
import TSideBar from "./components/sidebar/tablet/TSideBar";
import MultiSelectDropdown from "./components/MultiSelect";
import PhoneNumberInput from "./components/PhoneNumberInput";
import PaymentForm from "./pages/Payment/PaymentForm";
import CustomerWidget from "./widgets/CustomerWidget/CustomerWidget";
import CoachBookingSchedule from "./pages/coaches/coachbooking/CoachBookingSchedule";
import PrivateRoutes from "./utils/PrivateRoutes";
import ChatApp from "./ChatApp";
import { useState } from "react";
import FloatingButton from "./FloatingButton";
import Events from "./pages/events/Events";
import EventAttendenceForm from "./pages/events/EventAttendenceForm";
import Policy from "./pages/policy/Policy";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SurveyForms from "./pages/surveyforms/SurveyForms";
import BookingHistory from "./pages/BookingHistory/BookingHistory";
import CoachRequests from "./pages/CoachBookingRequests/CoachRequests";
import RoomCalendar from "./rooms-schedule/calendar/RoomCalendar";
import SurveyForm from "./surveyForm/SurveyForm";

function App() {
  const queryClient = new QueryClient();

  window.env_url = process.env.REACT_APP_API_URL;

  // const [showChatApp, setShowChatApp] = useState(false);

  return (
    <>
      {/* <div className="mobile-blocked">
        <p>Application is not available currently on mobile devices, we are working on it. <br />Please use a desktop browser.</p>
    </div> */}
      <div className="desktop-content">
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <ToastContainer />
            <Routes>
              <Route path="/" element={<LoginPage />}></Route>
              <Route path="/roomSchedule" element={<RoomCalendar />}></Route>
              <Route path="/events/:id" element={<EventAttendenceForm />} />
              <Route element={<PrivateRoutes />}>
                <Route path="/home" element={<Home />}></Route>
                <Route
                  path="/meeting-rooms"
                  element={
                    <MeetingFilterContextProvider>
                      <MeetingRooms />
                    </MeetingFilterContextProvider>
                  }
                ></Route>
                <Route
                  path="/coaches"
                  element={
                    <MeetingFilterContextProvider>
                      <Coaches />
                    </MeetingFilterContextProvider>
                  }
                ></Route>
                <Route path="/coachess" element={<RoomDetails />}></Route>
                <Route
                  path="/coach-details/:coachId"
                  element={<CoachDetails />}
                ></Route>
                <Route
                  path="/meeting-room-details/:roomId"
                  element={<MeetingRoomDetails />}
                ></Route>
                <Route
                  path="/room-booking"
                  element={<BookMeetingRoom />}
                ></Route>
                <Route path="/coach-booking" element={<BookCoach />}></Route>
                <Route
                  path="/booking-schedule/:id"
                  element={<BookingSchedule />}
                ></Route>
                <Route
                  path="/coach-booking-schedule/:id"
                  element={<CoachBookingSchedule />}
                ></Route>
                <Route path="/payment" element={<PaymentForm />}></Route>
                <Route path="/policy" element={<Policy />} />
                <Route path="/events" element={<Events />} />
                <Route path="/forms" element={<SurveyForms />} />
                <Route path="/booking-history" element={<BookingHistory />} />
                <Route path="/coach-requests" element={<CoachRequests />} />
                <Route path="/survey" element={<SurveyForm />} />
              </Route>
            </Routes>
            {/* <div>
            {showChatApp && <ChatApp onClose={() => setShowChatApp(false)} />}
            <FloatingButton onClick={() => setShowChatApp(true)} />
          </div> */}
          </BrowserRouter>
          <footer>
            <div className="footerChildDiv">© Axzora Inc.</div>
          </footer>
        </QueryClientProvider>
      </div>
    </>
  );
}

export default App;
