import React, { useEffect, useState } from "react";
import axios from "axios";
import { v4 } from "uuid";
import LoadingOverlay from "react-loading-overlay-ts";
import { useQuery } from "react-query";
import {
  fetchAllData,
  fetchUserDetails,
  fetchSingleData,
} from "../../utils/fetchData";
import { toast } from "react-toastify";
import "./CoachDetailsPage.css";
import CustomCloseIcon from "../../assets/svg/CustomCloseIcon";
import { token } from "../../utils/utilityFunctions";

function CoachDetailsPage({
  isOpen,
  onClose,
  detailsData,
  refetch,
  setRefreshState,
  bookId,
  fetch,
}) {
  const apiURL = process.env.REACT_APP_API_URL;
  const getFileUrl = `${window.env_url}/v1/files/`;
  const [userData, setUserData] = useState({});
  const [isRejected, setIsRejected] = useState(false);
  const [isAccepted, setIsAccepted] = useState(false);

  const bookingDataEndpoint = `/v1/coaches/${detailsData[0].bookedCoachId}`;
  const queryDataKey = "CoachBookRequest";
  const [meetingName, setMeetingName] = useState("");
  const [coachName, setCoachName] = useState("");
  const { data, isLoading } = useQuery(
    [queryDataKey, bookingDataEndpoint],
    () => fetchSingleData(bookingDataEndpoint),
    {
      onSuccess: (data) => {
        setCoachName(data);
        const extractedCoachName = data?.title || "";
        setCoachName(extractedCoachName);
        console.log("name for details", coachName);
      },
    }
  );

  const userEndpoint = "/v1/users";
  const {} = useQuery(
    ["User", userEndpoint],
    () => fetchUserDetails(userEndpoint),
    {
      onSuccess: (data) => {
        setUserData(data);
      },
    }
  );


  useEffect(() => {
    if (detailsData[0]?.visibility === "rejected") {
      setIsRejected(true);
      setIsAccepted(true);
    } else if (detailsData[0]?.visibility === "accepted") {
      setIsRejected(true);
      setIsAccepted(true);
    }
  }, [detailsData]);

  if (!isOpen || !detailsData) {
    return null;
  }
  //
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = {
      weekday: "short",
      day: "2-digit",
      month: "long",
      year: "numeric",
    };
    return date.toLocaleDateString("en-US", options);
  };
  //for time
  const formatTime = (dateString) => {
    const date = new Date(dateString);
    const options = {
      hour: "numeric",
      minute: "numeric",
      hour12: true, // Set to false if you want 24-hour format
    };
    return date.toLocaleTimeString("en-US", options);
  };
  console.log(detailsData);
  const bookingId = detailsData.length > 0 ? detailsData[0].id : null;


  const onAcceptHandle = async () => {
    try {
      const reqBody = {
        visibility: "accepted",
      };

      const url = `${apiURL}/v1/coachbookings/${bookingId}`;
      const statusResponse = await axios.patch(url, reqBody, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-type": "application/json",
        },
      });
      console.log("accept response", statusResponse);
      let imgPath;
      if (userData?.imageUrl.includes("avataaars.io")) {
        imgPath = userData?.imageUrl;
      } else {
        const responsImg = await axios.get(userData.imageUrl, {
          responseType: "blob",
        });

      }
      setIsAccepted(true);
      refetch(!fetch);
      onClose();
      //   window.location.reload();
    } catch (error) {
      console.error("Error accepting booking:", error);

      toast.warn(
        "Your meeting booking has been accepted, but there is no staff assigned to the room"
      );      
      setIsAccepted(true);
    }
  };

  const onRejectHandle = async () => {
    try {
      const reason = prompt(
        "Please provide a reason for declining the booking:"
      );
      if (!reason) return;

      const reqBody = {
        visibility: "rejected",
      };

      const url = `${apiURL}/v1/coachbookings/${bookingId}`;
      const statusResponse = await axios.patch(url, reqBody, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-type": "application/json",
        },
      });

      console.log(statusResponse, "enetrered");

      // Fetch and upload image
      let imgPath;
      if (userData?.imageUrl.includes("avataaars.io")) {
        imgPath = userData?.imageUrl;
      } else {
        const responsImg = await axios.get(userData.imageUrl, {
          responseType: "blob",
        });
        console.log("responsImg", responsImg);
      }
      setIsRejected(true);
      console.log("enetrered");
      onClose();
      refetch(!fetch);
      //   refetch();
    } catch (error) {
      console.error("Error rejecting booking:", error);
    }
  };

  console.log("detailsData[0]?.", detailsData[0]);

  return (
    <>
      <LoadingOverlay active={isLoading} spinner text="Loading your content...">
        <div className="detailsOverlay">
          <div className="detailsContentClass">
            <div className="detailsPageTopBarContainer">
              <div className="topBarPlaceholderDiv"></div>
              <h2>Booking Information </h2>
              <span className="closeIconClass" onClick={() => onClose()}>
                <CustomCloseIcon fill={"white"} />
              </span>
              <div className="detailsPageCloseBtn"></div>
            </div>
            {/* title of details */}
            <div className="detailsCard">
              <p className="detailsTitle">Guest Details</p>

              <div className="detailsData">
                <div className="textName">
                  <p>Guest Name </p>
                </div>
                <div className="textDesc">
                  <p>
                    {" "}
                    <span>:</span>
                    {detailsData[0].user.firstname +
                      " " +
                      detailsData[0].user.lastname}
                  </p>
                </div>
              </div>
              <div className="detailsData">
                <div className="textName">
                  <p>Email id</p>
                </div>
                <div className="textDesc">
                  <p>
                    {" "}
                    <span>:</span>
                    {detailsData[0].user.email}
                  </p>
                </div>
              </div>

              <div className="detailsData">
                <div className="textName">
                  <p>Mobile Number</p>
                </div>
                <div className="textDesc">
                  <p>
                    {" "}
                    <span>:</span>
                    {detailsData[0].user.phone || "-"}
                  </p>
                </div>
              </div>
              <div className="detailsData">
                <div className="textName">
                  <p>Booking Time</p>
                </div>
                <div className="textDesc">
                  <p>
                    {" "}
                    <span>:</span>
                    {new Date(
                      "2000-01-01T" + detailsData[0].checkInTime
                    ).toLocaleTimeString([], {
                      hour: "2-digit",
                      minute: "2-digit",
                    })}
                  </p>
                </div>
              </div>
            </div>

            <div className="detailsCard cardMarginClass">
              <p className="detailsTitle">Reservations Details</p>
              <div className="detailsData">
                <div className="textName">
                  <p>Service</p>
                </div>
                <div className="textDesc">
                  <p className="capsClass">
                    {" "}
                    <span>:</span>
                    {"Coach"}
                  </p>
                </div>
              </div>

              <div className="detailsData">
                <div className="textName">
                  <p>Reservation Date</p>
                </div>
                <div className="textDesc">
                  <p>
                    {" "}
                    <span>:</span>
                    {formatDate(detailsData[0].checkInDate)}
                  </p>
                </div>
              </div>

              <div className="detailsData">
                <div className="textName">
                  <p>Duration</p>
                </div>
                <div className="textDesc">
                  <p>
                    {" "}
                    <span>:</span>
                    {detailsData[0].duration}
                  </p>
                </div>
              </div>

              <div className="detailsData">
                <div className="textName">
                  <p>{"Coach Name:"}</p>
                </div>
                <div className="textDesc">
                  <p>
                    <span>:</span>
                    {coachName}
                  </p>
                </div>
              </div>
              {/* notes */}
              <div className="detailsData">
                <div className="textName">
                  <p>Notes</p>
                </div>
                <div className="textDesc">
                  <p>
                    {" "}
                    <span>:</span>
                    {detailsData[0].notes}
                  </p>
                </div>
              </div>
              {/* notes code ends */}
              {detailsData[0]?.isRescheduled && (
                <>
                  <div className="detailsData">
                    <div className="textName">
                      <p>Previous Booking Date</p>
                    </div>
                    <div className="textDesc">
                      <p>
                        {" "}
                        <span>:</span>
                        {formatDate(detailsData[0]?.prevBookingDate)}
                      </p>
                    </div>
                  </div>

                  <div className="detailsData">
                    <div className="textName">
                      <p>Rescheduled Date</p>
                    </div>
                    <div className="textDesc">
                      <p>
                        {" "}
                        <span>:</span>
                        {formatDate(detailsData[0]?.checkInDate)}
                      </p>
                    </div>
                  </div>
                </>
              )}
            </div>

            <div className="detailsBtnWrapper">
              <button
                onClick={onRejectHandle}
                disabled={isRejected}
                className={isRejected ? "disabledButton" : ""}
              >
                Decline
              </button>
              <button
                onClick={onAcceptHandle}
                disabled={isAccepted}
                className={isAccepted ? "disabledButton" : ""}
              >
                Accept
              </button>
            </div>
          </div>
        </div>
      </LoadingOverlay>
    </>
  );
}

export default CoachDetailsPage;
